<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Sug'urta Ma'lumotlari</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :value="allData.truck_number | dateFilter"
                  disabled
                  dense
                  label="Mashina raqami"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :value="allData.reg_date | dateFilter"
                  disabled
                  dense
                  label="Ro'yxat o'tgan sana"
                  readonly
                  append-icon="event"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :value="allData.expires | dateFilter"
                  disabled
                  dense
                  label="Tugash sanasi"
                  append-icon="event"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :value="allData.given_by"
                  disabled
                  label="Berilgan tashkilot nomi"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :value="allData.reg_number"
                  disabled
                  label="Reg raqami"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data: () => ({
    allData: {}
  }),
  created() {
    this.$store.dispatch('getInsuranceTruckDetails', this.$route.params.id)
  },
  watch: {
    truckListsDetails: function (val) {
      val.truck_number = val.truck_info.truck_number
      this.allData = val
    }
  },
  computed: {
    truckListsDetails() {
      var data = this.$store.state.requests.insuranceTruckDetails
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sug'urta Ma'lumotlari" }])
  }
}
</script>
